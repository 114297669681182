import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Layout from "../layout/Index";
import HomePage from "../pages/HomePage";
import UXPage from "../pages/axiomlayout/pages/pages/UXPage";
import GrapgicsPage from "../pages/axiomlayout/pages/pages/GrapgicsPage";
import ThreeDPage from "../pages/axiomlayout/pages/pages/ThreeDPage";
import GamePage from "../pages/axiomlayout/pages/pages/GamePage";
import WebDev from "../pages/axiomlayout/pages/pages/WebDev";
import ContactUs from "../pages/axiomlayout/pages/pages/ContactUs";
import EcomPage from "../pages/axiomlayout/pages/pages/EcomPage";
import ErpCrmpage from "../pages/axiomlayout/pages/pages/ErpCrmpage";
import MobileAppPage from "../pages/axiomlayout/pages/pages/MobileAppPage";
import WebHostingPage from "../pages/axiomlayout/pages/pages/WebHostingPage";
import DigitalMarketing from "../pages/axiomlayout/pages/pages/DigitalMarketing";
import Domainservice from "../pages/axiomlayout/pages/pages/Domainservice";
import ServerHosting from "../pages/axiomlayout/pages/pages/ServerHosting";
import WebSupport from "../pages/axiomlayout/pages/pages/WebSupport";
import SocialMediaPage from "../pages/axiomlayout/pages/pages/SocialMediaPage";
import BrandEvolution from "../pages/axiomlayout/pages/pages/BrandEvolution";
import ContentMarketing from "../pages/axiomlayout/pages/pages/ContentMarketing";
import SiloSecureData from "../pages/SiloSecureData";
import AppLayout from "../layout/AppLayout";
import AboutUs from "../pages/silosecuredata/Pages/AboutUs";
import Portfolio from "../pages/axiomlayout/pages/pages/Portfolio";
import Service from "../pages/axiomlayout/pages/pages/Service";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";
import Consultation from "../pages/axiomlayout/pages/pages/Consultation";
import SiloConsultation from "../pages/silosecuredata/Pages/SiloCunsultation";
import Portfoliosilo from "../pages/silosecuredata/components/Portfoliosilo";

const domainConfig = {
  "axiomwebcontrol.com": {
    homeComponent: HomePage,
    layout: Layout,
  },
  "silosecuredata.com": {
    homeComponent: SiloSecureData,
    layout: AppLayout,
  },
  "localhost": {
    homeComponent: SiloSecureData,
    layout: AppLayout,
  },
  // "localhost": {
  //   homeComponent: HomePage,
  //   layout: Layout,
  // },
};

const Pages = () => {
  const location = useLocation();
  const domain = window.location.hostname;
  const { homeComponent: HomePageComponent, layout: LayoutComponent } = domainConfig[domain] || {};

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<LayoutComponent />}>
        <Route index element={<HomePageComponent />} />
        <Route path="ui/uxservices" element={<UXPage />} />
        <Route path="graphicsservices" element={<GrapgicsPage />} />
        <Route path="3Dservices" element={<ThreeDPage />} />
        <Route path="gamedesignservices" element={<GamePage />} />
        <Route path="static&dynamicweb" element={<WebDev />} />
        <Route path="e-commerceservices" element={<EcomPage />} />
        <Route path="erpcrmservices" element={<ErpCrmpage />} />
        <Route path="mobileappdevelopment" element={<MobileAppPage />} />
        <Route path="webhostingservice" element={<WebHostingPage />} />
        <Route path="digitalmarketing" element={<DigitalMarketing />} />
        <Route path="domainregisterservice" element={<Domainservice />} />
        <Route path="serverhostingservice" element={<ServerHosting />} />
        <Route path="websupportservice" element={<WebSupport />} />
        <Route path="socialmediaservices" element={<SocialMediaPage />} />
        <Route path="brandevolutionservices" element={<BrandEvolution />} />
        <Route path="contentmarketingservices" element={<ContentMarketing />} />
        <Route path="services" element={<Service />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="siloportfolio" element={<Portfoliosilo />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="consultation" element={<Consultation />} />
        <Route path="bookconsultation" element={<SiloConsultation />} />

        <Route path="about" element={<AboutUs />} />

        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </Routes>
  );
};

export default Pages;
