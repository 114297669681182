import React, { useEffect, useState } from 'react'
import { Row, Col, TabContent, TabPane, Card, CardHeader, CardBody, CardText, CardTitle, Button, CardFooter } from "reactstrap";
import tabimg1 from '../../../../images/siloimages/ui_ux.png';
import tabimg2 from '../../../../images/siloimages/graphics.png';
import tabimg3 from '../../../../images/siloimages/3D.png';
import tabimg4 from '../../../../images/siloimages/game.png';
import tabimg5 from '../../../../images/siloimages/static&dynamic.png';
import tabimg6 from '../../../../images/siloimages/ecom.png';
import tabimg7 from '../../../../images/siloimages/erp_crm.png';
import tabimg8 from '../../../../images/siloimages/mobiledev.png';
import tabimg9 from '../../../../images/siloimages/webhosting.png';
import tabimg10 from '../../../../images/siloimages/Domainmanage.png';
import tabimg11 from '../../../../images/siloimages/server.png';
import tabimg12 from '../../../../images/siloimages/websupport.png';
import tabimg13 from '../../../../images/siloimages/digital_marketing.png';
import tabimg14 from '../../../../images/siloimages/social_media.png';
import tabimg15 from '../../../../images/siloimages/Brand.png';
import tabimg16 from '../../../../images/siloimages/content.png';
import { Collapse } from "reactstrap";
import { Icon } from "../../../../components/Component";
import classnames from "classnames";
import { Link } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';


const VerticalTabs = ({ className, variation, ...props }) => {
    const [isOpen, setIsOpen] = useState("1");
    const [borderLink, setBorderLink] = useState("");
    const [activeTab, setActiveTab] = useState("1");
    const [animationClass, setAnimationClass] = useState("");
    const [previousTab, setPreviousTab] = useState("");

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setPreviousTab(activeTab);
            setAnimationClass("card-disappear");

            setTimeout(() => {
                setActiveTab(tab);
                setAnimationClass("card-appear");
            }, 5);
        }
    };

    const toggleCollapse = (param) => {
        if (param === isOpen) {
            setIsOpen("0");
        } else {
            setIsOpen(param);
        }
    };
    const handleBorderLinkChange = (index) => {
        setBorderLink(index);
    };
    const [height, setHeight] = useState('580px');

    const updateHeight = () => {
        const width = window.innerWidth;
        if (width >= 1200) {
            setHeight('530px');
        } else if (width >= 992) {
            setHeight('520px');
        } else if (width >= 768) {
            setHeight('100%');
        } else if (width <= 576) {
            setHeight('100%');
        } else {
            setHeight('580px');
        }
    };
    useEffect(() => {
        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => window.removeEventListener('resize', updateHeight);
    }, [])

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    })
    const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 768px)'
    })
    return (
        <div className='nk-content py-0 z-n1' >
            <div className=''>
                <Row className="g-gs">
                    <Col md={12} lg={4}>
                        <div className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`, 'border-0']}>
                            <div className="accordion-item border-0">
                                <div className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""} border-0 px-lg-4 px-md-3 px-1`]} onClick={() => toggleCollapse("1")} style={{ cursor: 'pointer' }}>
                                    <div className={`d-flex align-items-center`}>
                                        <div className="accordhead">
                                            <p className="title ff-alt fs-4 me-4" style={{ color: '#17253D' }}>Design Services</p>
                                        </div>
                                        <Icon
                                            name={isOpen === "1" ? "chevron-up" : "chevron-down"}
                                            className="fs-2"
                                        />
                                    </div>
                                    {isOpen === "1" && (
                                        <hr className='border-bottom border-top-0 border-4 border-primary mb-0' width="50%" />
                                    )}
                                </div>
                                <Collapse className="accordion-body border-0" isOpen={isOpen === "1" ? true : false}>
                                    <div className="accordion-inner py-0 border-0">
                                        <ul className="px-md-2 px-0 nav link-list-menu">
                                            <li
                                                href="#tab"
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "0" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "1" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('0');
                                                    toggle("1");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >UI/UX Design</li>
                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "1" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "2" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('1');
                                                    toggle("2");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >Graphics Design</li>
                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "2" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "3" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('2');
                                                    toggle("3");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >3D Design</li>
                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "3" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "4" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('3');
                                                    toggle("4");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >Game Design</li>
                                        </ul>
                                    </div>
                                    {isTabletOrMobile && (
                                        <div className='py-5'>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">UI/UX Design
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg1} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>The Foundation for Exceptional UI/UX Design</CardTitle>
                                                                <CardText className='p-2 text-dark '>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Granular User Segmentation: </span>
                                                                                Silo Secured Data empowers us to create highly detailed user personas, ensuring every design element resonates with your specific target audience segments
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Unparalleled Data Integrity: </span>
                                                                                User privacy is never compromised. Silo Secured Data keeps sensitive information compartmentalized and protected from unauthorized access.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Data-Driven Design Decisions: </span>
                                                                                Backed by robust, secure data, our design team crafts intuitive, efficient interfaces that foster user trust and confidence.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Graphic Design
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg2} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>The Catalyst for Compelling Graphic Design</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Targeted Audience Insights: </span>
                                                                                Secure user data fuels the creation of highly targeted creative assets. We understand your audience demographics, preferences and behaviours, allowing for visuals that truly connect.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Data-Driven Color Psychology: </span>
                                                                                LeveragingSilo Secure Data, we move beyond guesswork. We analyze user color preferences to inform color palette selections that evoke specific emotions and drive engagement.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Personalized Visual Experiences: </span>
                                                                                Silo Secure Data empowers us to tailor visuals based on user behaviour. This personalization fosters deeper connections with your audience and strengthens brand loyalty.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="3">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">3D Design
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg3} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>The Bedrock of High-Fidelity 3D Design</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Dimensional Accuracy: </span>
                                                                                Secure user data informs the creation of highly detailed 3D models, guaranteeing precise replication of real-world objects or environments.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Material Selection Optimization: </span>
                                                                                By leveragingSilo Secure Data on user preferences and purchasing habits, we craft 3D models with materials that resonate with your target audience, enhancing their visual appeal and perceived value.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Intuitive User Interaction: </span>
                                                                                Data guides the design of user-friendly 3D interfaces, fostering seamless user experiences in virtual reality (VR) or augmented reality (AR) applications.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="4">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Game Design
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg4} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>Fueling Next-Gen Game Design</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Granular Player Segmentation: </span>
                                                                                Secure player data allows for the development of highly detailed player personas, enabling the creation of personalized in-game journeys that resonate with diverse player demographics and preferences.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Data-Driven Game Balancing:</span>  We leverage anonymized player data to identify and address balance issues within gameplay mechanics. This ensures a consistently fair and engaging experience for all players.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Immersive World Design Informed by Data:</span> Secure player data informs the creation of captivating in-game environments tailored to player preferences. This fosters deeper immersion and a stronger connection with the game world.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    )}
                                </Collapse>
                            </div>
                            <div className="accordion-item border-0">
                                <div className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""} border-0 px-lg-4 px-md-3 px-1`]} onClick={() => toggleCollapse("2")} style={{ cursor: 'pointer' }}>
                                    <div className={`d-flex align-items-center`}>
                                        <div className="accordhead">
                                            <p className="title ff-alt fs-4 me-4" style={{ color: '#17253D' }}>Software Development</p>
                                        </div>
                                        <Icon name={isOpen === "2" ? "chevron-up" : "chevron-down"} className="fs-2" />
                                    </div>
                                    {isOpen === "2" && (
                                        <hr className='border-bottom border-top-0 border-4 border-primary mb-0' width="65%" />
                                    )}
                                </div>
                                <Collapse className="accordion-body border-0" isOpen={isOpen === "2" ? true : false}>
                                    <div className="accordion-inner py-0 border-0">
                                        <ul className="px-md-2 px-0 nav link-list-menu">

                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "4" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "5" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('4');
                                                    toggle("5");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >Static & Dynamic Website</li>

                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "5" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "6" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('5');
                                                    toggle("6");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >E-Commerce Website</li>

                                            <li className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "6" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "7" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('6');
                                                    toggle("7");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >ERP / CRM</li>

                                            <li className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "7" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "8" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('7');
                                                    toggle("8");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >Mobile App</li>
                                        </ul>
                                    </div>
                                    {isTabletOrMobile && (
                                        <div className='py-5'>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="5">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Static & Dynamic Websites
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg5} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>Building Exceptional Static & Dynamic Websites</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Personalize Static & Dynamic Content: </span>
                                                                                Deliver targeted content and user experiences across both static and dynamic website elements for deeper connections and conversions
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Optimize Performance & Scalability: </span>
                                                                                Ensure fast loading times and a scalable website that adapts to your traffic, regardless of website type.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Drive Results (Dynamic): </span>
                                                                                Leverage data for A/B testing, refining dynamic elements for maximum user engagement and conversions.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="6">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">E-Commerce Website
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg6} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>Powering Exceptional E-commerce Experiences</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Personalize Customer Journeys: </span>
                                                                                Targeted recommendations and promotions drive sales and loyalty.

                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Optimize Inventory & Placement: </span>
                                                                                Data insights minimize lost sales and maximize profitability.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Boost Security & Trust: </span>
                                                                                Secure environments build customer confidence for a frictionless shopping experience.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <CardFooter className='bg-white px-0 text-dark '>
                                                                        We create data-driven e-commerce solutions that convert. Let's discuss how secure data can elevate your online store.
                                                                    </CardFooter>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="7">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">ERP/CRM
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg7} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>The Power Behind Your ERP/CRM Website</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Streamlined Data Integration: </span>
                                                                                Silo Secure Data architecture eliminates the complexities of system integration. Your ERP/CRM website seamlessly connects with existing infrastructure, fostering a unified data landscape for streamlined operations.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Enhanced Data Governance and Visibility: </span>
                                                                                Gain comprehensive control and clear visibility into your data. Silo Secure Data empowers informed decision-making at all levels, ensuring strategic business practices.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Robust Security and User Access Control: </span>
                                                                                Our secure data environments safeguard sensitive information and maintain granular user access controls. This mitigates security risks and ensures data integrity within your ERP/CRM ecosystem.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="8">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Mobile Application
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg8} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>Secure & User-Centric Mobile Apps</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Personalized User Journeys: </span>
                                                                                Securely stored user data empowers us to craft highly targeted app experiences. This ensures each user receives an experience tailored to their preferences, fostering deeper engagement and driving long-term retention.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '> Unparalleled Security and Privacy: </span>
                                                                                Silo Secure Data compartments create a secure environment for sensitive user information. This builds trust and confidence in your app, enhancing user experience and brand loyalty.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Data-Driven App Optimization: </span>
                                                                                Leveraging anonymized user data unlocks valuable insights. We utilize these insights to continuously improve your app's design, functionality and user flow, guaranteeing a seamless and efficient mobile experience.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    )}
                                </Collapse>
                            </div>
                            <div className="accordion-item border-0">
                                <div className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""} border-0 px-lg-4 px-md-3 px-1`]} onClick={() => toggleCollapse("3")} style={{ cursor: 'pointer' }}>
                                    <div className={`d-flex align-items-center`}>
                                        <div className="accordhead">
                                            <p className="title ff-alt fs-4 me-4" style={{ color: '#17253D' }}>Web Infrastructure & Management</p>
                                        </div>
                                        <Icon name={isOpen === "3" ? "chevron-up" : "chevron-down"} className="fs-2" />
                                    </div>
                                    {isOpen === "3" && (
                                        <hr className='border-bottom border-top-0 border-4 border-primary mb-0' width="93%" />
                                    )}
                                </div>
                                <Collapse className="accordion-body border-0 " isOpen={isOpen === "3" ? true : false}>
                                    <div className="accordion-inner py-0 border-0">
                                        <ul className="px-md-2 px-0 nav link-list-menu">

                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "8" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "9" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('8');
                                                    toggle("9");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >Web Hosting Services</li>

                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "9" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "10" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('9');
                                                    toggle("10");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >Domain Registration and Management</li>

                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "10" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "11" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('10');
                                                    toggle("11");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >Dedicated Server Hosting</li>

                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "11" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "12" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('11');
                                                    toggle("12");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >Website Maintenance & Support</li>
                                        </ul>
                                    </div>
                                    {isTabletOrMobile && (
                                        <div className='py-5'>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="9">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Website Hosting
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg9} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>Building Exceptional Static & Dynamic Websites</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Unparalleled Data Isolation: </span>
                                                                                Your data remains completely segregated from other users on our servers, minimizing security vulnerabilities and unauthorized access attempts.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Enhanced Performance and Scalability: </span>
                                                                                Silo secured data allows for optimized server resource allocation, guaranteeing a consistently fast and reliable hosting experience for your website, regardless of traffic fluctuations.

                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Robust Compliance and Peace of Mind: </span>
                                                                                Strict access controls and industry-compliant security protocols ensure your data meets the most rigorous regulations, giving you peace of mind and fostering trust with your visitors.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="10">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Domain Registration & <br />Management
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg10} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>Secure Your Domain, Build Brand Trust</CardTitle>
                                                                <CardText className='text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Uncompromising Protection: </span>
                                                                                Isolated data minimizes security risks, ensuring domain integrity and brand reputation.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Strict Privacy: </span>
                                                                                Rigorous protocols keep your domain information confidential, fostering user trust.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Simplified Security: </span>
                                                                                Manage your domain with ease, knowing it's protected by the latest measures.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <CardFooter className='bg-white px-0 text-dark'>
                                                                        Focus on building your online presence, with confidence in our secure domain solutions. Let's discuss how we can empower your online success.
                                                                    </CardFooter>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="11">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Dedicated Server Hosting
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg11} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>Dedicated Security for Dedicated Servers</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '> Unmatched Isolation & Control: </span>
                                                                                Your data on a server solely for you. Configure robust security tailored to your needs.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Enhanced Security Posture: </span>
                                                                                Minimized attack surface reduces vulnerabilities and strengthens trust.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Peak Performance & Scalability: </span>
                                                                                Dedicated resources and isolated data ensure seamless user experience and effortless scaling
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <CardFooter className='bg-white px-0 '>
                                                                        Go beyond traditional hosting. Secure your mission-critical data and unlock business growth with Silo Secure Data architecture. Let's discuss your needs.
                                                                    </CardFooter>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="12">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Website Maintenance & <br />Support
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg12} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>Powering Top-Tier Website Maintenance</CardTitle>
                                                                <CardText className='text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Faster Issue Resolution: </span>
                                                                                Secure, organized data allows for swift diagnosis and fixes, minimizing downtime.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Proactive Security: </span>
                                                                                Silo data enables us to proactively identify and address security threats before they impact your website.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Effortless Maintenance: </span>
                                                                                Secure backups and version control ensure efficient website updates and rollbacks.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <CardFooter className='bg-white px-0 text-black'>
                                                                        We secure your data to keep your website secure, reliable and performing at its best.
                                                                    </CardFooter>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    )}
                                </Collapse>
                            </div>
                            <div className="accordion-item border-0" >
                                <div className={[`accordion-head${isOpen !== "4" ? " collapsed" : ""} border-0 px-lg-4 px-md-3 px-1`]} onClick={() => toggleCollapse("4")} style={{ cursor: 'pointer' }}>
                                    <div className={`d-flex align-items-center`}>
                                        <div className="accordhead">
                                            <p className="title ff-alt fs-4 me-4" style={{ color: '#17253D' }}>Digital Marketing & Maintenance</p>
                                        </div>
                                        <Icon name={isOpen === "4" ? "chevron-up" : "chevron-down"} className="fs-2" />
                                    </div>
                                    {isOpen === "4" && (
                                        <hr className='border-bottom border-top-0 border-4 border-primary mb-0' width="90%" />
                                    )}
                                </div>
                                <Collapse className="accordion-body border-0" isOpen={isOpen === "4" ? true : false}>
                                    <div className="accordion-inner py-0 border-0">
                                        <ul className="px-md-2 px-0">
                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "12" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "13" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('12');
                                                    toggle("13");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >SEO & Digital Marketing</li>
                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "13" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "14" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('13');
                                                    toggle("14");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >Social Media Management & Creation</li>

                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "14" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "15" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('14');
                                                    toggle("15");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >Brand Evolution</li>

                                            <li
                                                className={`pb-2 fs-6 ff-alt p-2 ${borderLink === "15" ? "bg-gray-100" : ""} ${classnames({ active: activeTab === "16" })}`}
                                                onMouseLeave={() => setBorderLink("")}
                                                onMouseEnter={(ev) => {
                                                    ev.preventDefault();
                                                    handleBorderLinkChange('15');
                                                    toggle("16");
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >Content Marketing</li>
                                        </ul>
                                    </div>
                                    {isTabletOrMobile && (
                                        <div className='py-5'>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="13">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">SEO & Digital Marketing
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg13} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>Elevate Your SEO & Digital Marketing</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Targeted Audiences: </span>
                                                                                We create laser-focused strategies with personalized experiences that resonate deeply.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Data-Driven Content: </span>
                                                                                Craft high-quality content aligned with user intent for top search rankings and qualified traffic.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Measurable ROI: </span>
                                                                                Track and optimize campaigns with secure data, ensuring maximum return on investment.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <CardFooter className='bg-white px-0 text-black'>
                                                                        We secure your data to fuel results.  Let's discuss your SEO & digital marketing goals.
                                                                    </CardFooter>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="14">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Social Media Management & <br />Creation
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg14} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>Your Brand's Trusted Partner</CardTitle>
                                                                <CardText className='text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Target the Right Audience: </span>
                                                                                Reach the perfect demographic with laser-focused content.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Craft Engaging Content: </span>
                                                                                Secure data guides content creation for maximum engagement.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Optimize & Measure: </span>
                                                                                Track results and refine strategies for peak performance.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <CardFooter className='bg-white px-0 text-black'>
                                                                        We secure your data to fuel social media success.
                                                                    </CardFooter>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="15">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Brand Evolution
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg15} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>The Strategic Foundation for Brand Evolution</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Granular Customer Insights: </span>
                                                                                We analyze secure data to uncover hidden patterns, revealing a deeper understanding of your target audience..
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Brand Strategy Development: </span>
                                                                                These insights inform a data-backed brand strategy, ensuring your message resonates and drives engagement.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Quantifiable Success Measurement: </span>
                                                                                Secure data lets us track key metrics and optimize your brand strategy for continuous improvement and success.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <CardFooter className='bg-white px-0 text-black'>
                                                                        We partner with you to evolve your brand with confidence, guided by the power of secure data.
                                                                    </CardFooter>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="16">
                                                    <div className='container'>
                                                        <Card className="card-bordered rounded-5 shadow-lg ff-alt" style={{ height }}>
                                                            <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Content Marketing
                                                                <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                                    <img src={tabimg16} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                                                </div>
                                                            </CardHeader>
                                                            <CardBody className="card-inner">
                                                                <CardTitle tag="h5" className='text-dark'>High-Impact Content Marketing</CardTitle>
                                                                <CardText className='p-2 text-dark'>
                                                                    Our approach offers distinct advantages:
                                                                    <ul className='list'>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Targeted Content: </span>
                                                                                Craft hyper-personalized content that resonates with specific audiences, driving deeper engagement.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Data-Driven Strategy: </span>
                                                                                Analyze user behaviour to create content aligned with user intent, maximizing reach and qualified traffic.
                                                                            </p>
                                                                        </li>
                                                                        <li>
                                                                            <p>
                                                                                <span className='fw-semibold text-dark '>Measurable ROI: </span>
                                                                                Track key metrics with secure data to optimize your strategy and ensure maximum return on investment.
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                    <CardFooter className='bg-white px-0 text-black'>
                                                                        We secure your data to unlock content marketing's full potential.
                                                                    </CardFooter>
                                                                    <div className="py-3 d-flex justify-content-center " >
                                                                        <Link to="contact"
                                                                            className='scrollto'
                                                                            smooth={true}
                                                                            offset={-10}
                                                                            duration={100}>
                                                                            <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                                                <span className=''>Book Consultation</span>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    )}
                                </Collapse>
                            </div>
                        </div>
                    </Col>
                    <Col md={12} lg={8}>
                        {isDesktopOrLaptop && <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <div className="container">
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "1" ? animationClass : previousTab === "1" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className="bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">UI/UX Design
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg1} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>The Foundation for Exceptional UI/UX Design</CardTitle>
                                            <CardText className='p-2 text-dark '>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Granular User Segmentation: </span>
                                                            Silo Secured Data empowers us to create highly detailed user personas, ensuring every design element resonates with your specific target audience segments
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Unparalleled Data Integrity: </span>
                                                            User privacy is never compromised. Silo Secured Data keeps sensitive information compartmentalized and protected from unauthorized access.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Data-Driven Design Decisions: </span>
                                                            Backed by robust, secure data, our design team crafts intuitive, efficient interfaces that foster user trust and confidence.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className={`container`}>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "2" ? animationClass : previousTab === "1" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Graphic Design
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg2} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>The Catalyst for Compelling Graphic Design</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Targeted Audience Insights: </span>
                                                            Secure user data fuels the creation of highly targeted creative assets. We understand your audience demographics, preferences and behaviours, allowing for visuals that truly connect.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Data-Driven Color Psychology: </span>
                                                            LeveragingSilo Secure Data, we move beyond guesswork. We analyze user color preferences to inform color palette selections that evoke specific emotions and drive engagement.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Personalized Visual Experiences: </span>
                                                            Silo Secure Data empowers us to tailor visuals based on user behaviour. This personalization fosters deeper connections with your audience and strengthens brand loyalty.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "3" ? animationClass : previousTab === "2" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">3D Design
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg3} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>The Bedrock of High-Fidelity 3D Design</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Dimensional Accuracy: </span>
                                                            Secure user data informs the creation of highly detailed 3D models, guaranteeing precise replication of real-world objects or environments.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Material Selection Optimization: </span>
                                                            By leveraging Silo Secure Data on user preferences and purchasing habits, we craft 3D models with materials that resonate with your target audience, enhancing their visual appeal and perceived value.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Intuitive User Interaction: </span>
                                                            Data guides the design of user-friendly 3D interfaces, fostering seamless user experiences in virtual reality (VR) or augmented reality (AR) applications.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="4">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "4" ? animationClass : previousTab === "3" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Game Design
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg4} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>Fueling Next-Gen Game Design</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Granular Player Segmentation: </span>
                                                            Secure player data allows for the development of highly detailed player personas, enabling the creation of personalized in-game journeys that resonate with diverse player demographics and preferences.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Data-Driven Game Balancing:</span>  We leverage anonymized player data to identify and address balance issues within gameplay mechanics. This ensures a consistently fair and engaging experience for all players.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Immersive World Design Informed by Data:</span> Secure player data informs the creation of captivating in-game environments tailored to player preferences. This fosters deeper immersion and a stronger connection with the game world.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="5">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "5" ? animationClass : previousTab === "4" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Static & Dynamic Websites
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg5} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>Building Exceptional Static & Dynamic Websites</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Personalize Static & Dynamic Content: </span>
                                                            Deliver targeted content and user experiences across both static and dynamic website elements for deeper connections and conversions
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Optimize Performance & Scalability: </span>
                                                            Ensure fast loading times and a scalable website that adapts to your traffic, regardless of website type.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Drive Results (Dynamic): </span>
                                                            Leverage data for A/B testing, refining dynamic elements for maximum user engagement and conversions.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="6">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "6" ? animationClass : previousTab === "5" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">E-Commerce Website
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg6} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>Powering Exceptional E-commerce Experiences</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Personalize Customer Journeys: </span>
                                                            Targeted recommendations and promotions drive sales and loyalty.

                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Optimize Inventory & Placement: </span>
                                                            Data insights minimize lost sales and maximize profitability.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Boost Security & Trust: </span>
                                                            Secure environments build customer confidence for a frictionless shopping experience.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <CardFooter className='bg-white px-0 text-dark '>
                                                    We create data-driven e-commerce solutions that convert. Let's discuss how secure data can elevate your online store.
                                                </CardFooter>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="7">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "7" ? animationClass : previousTab === "6" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">ERP/CRM
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg7} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>The Power Behind Your ERP/CRM Website</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Streamlined Data Integration: </span>
                                                            Silo Secure Data architecture eliminates the complexities of system integration. Your ERP/CRM website seamlessly connects with existing infrastructure, fostering a unified data landscape for streamlined operations.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Enhanced Data Governance and Visibility: </span>
                                                            Gain comprehensive control and clear visibility into your data. Silo Secure Data empowers informed decision-making at all levels, ensuring strategic business practices.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Robust Security and User Access Control: </span>
                                                            Our secure data environments safeguard sensitive information and maintain granular user access controls. This mitigates security risks and ensures data integrity within your ERP/CRM ecosystem.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="8">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "8" ? animationClass : previousTab === "7" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Mobile Application
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg8} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>Secure & User-Centric Mobile Apps</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Personalized User Journeys: </span>
                                                            Securely stored user data empowers us to craft highly targeted app experiences. This ensures each user receives an experience tailored to their preferences, fostering deeper engagement and driving long-term retention.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '> Unparalleled Security and Privacy: </span>
                                                            Silo Secure Data compartments create a secure environment for sensitive user information. This builds trust and confidence in your app, enhancing user experience and brand loyalty.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Data-Driven App Optimization: </span>
                                                            Leveraging anonymized user data unlocks valuable insights. We utilize these insights to continuously improve your app's design, functionality and user flow, guaranteeing a seamless and efficient mobile experience.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="9">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "9" ? animationClass : previousTab === "8" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Website Hosting
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg9} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>Building Exceptional Static & Dynamic Websites</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Unparalleled Data Isolation: </span>
                                                            Your data remains completely segregated from other users on our servers, minimizing security vulnerabilities and unauthorized access attempts.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Enhanced Performance and Scalability: </span>
                                                            Silo secured data allows for optimized server resource allocation, guaranteeing a consistently fast and reliable hosting experience for your website, regardless of traffic fluctuations.

                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Robust Compliance and Peace of Mind: </span>
                                                            Strict access controls and industry-compliant security protocols ensure your data meets the most rigorous regulations, giving you peace of mind and fostering trust with your visitors.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="10">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "10" ? animationClass : previousTab === "9" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Domain Registration & <br />Management
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg10} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>Secure Your Domain, Build Brand Trust</CardTitle>
                                            <CardText className='text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Uncompromising Protection: </span>
                                                            Isolated data minimizes security risks, ensuring domain integrity and brand reputation.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Strict Privacy: </span>
                                                            Rigorous protocols keep your domain information confidential, fostering user trust.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Simplified Security: </span>
                                                            Manage your domain with ease, knowing it's protected by the latest measures.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <CardFooter className='bg-white px-0 text-dark'>
                                                    Focus on building your online presence, with confidence in our secure domain solutions. Let's discuss how we can empower your online success.
                                                </CardFooter>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="11">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "11" ? animationClass : previousTab === "10" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Dedicated Server Hosting
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg11} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>Dedicated Security for Dedicated Servers</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '> Unmatched Isolation & Control: </span>
                                                            Your data on a server solely for you. Configure robust security tailored to your needs.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Enhanced Security Posture: </span>
                                                            Minimized attack surface reduces vulnerabilities and strengthens trust.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Peak Performance & Scalability: </span>
                                                            Dedicated resources and isolated data ensure seamless user experience and effortless scaling
                                                        </p>
                                                    </li>
                                                </ul>
                                                <CardFooter className='bg-white px-0 '>
                                                    Go beyond traditional hosting. Secure your mission-critical data and unlock business growth with Silo Secure Data architecture. Let's discuss your needs.
                                                </CardFooter>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="12">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "12" ? animationClass : previousTab === "12" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Website Maintenance & <br />Support
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg12} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>Powering Top-Tier Website Maintenance</CardTitle>
                                            <CardText className='text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Faster Issue Resolution: </span>
                                                            Secure, organized data allows for swift diagnosis and fixes, minimizing downtime.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Proactive Security: </span>
                                                            Silo data enables us to proactively identify and address security threats before they impact your website.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Effortless Maintenance: </span>
                                                            Secure backups and version control ensure efficient website updates and rollbacks.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <CardFooter className='bg-white px-0 text-black'>
                                                    We secure your data to keep your website secure, reliable and performing at its best.
                                                </CardFooter>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="13">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "13" ? animationClass : previousTab === "12" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">SEO & Digital Marketing
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg13} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>Optimize Your SEO & Digital Marketing</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Targeted Audiences: </span>
                                                            We create laser-focused strategies with personalized experiences that resonate deeply.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Data-Driven Content: </span>
                                                            Craft high-quality content aligned with user intent for top search rankings and qualified traffic.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Measurable ROI: </span>
                                                            Track and optimize campaigns with secure data, ensuring maximum return on investment.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <CardFooter className='bg-white px-0 text-black'>
                                                    We secure your data to fuel results.  Let's discuss your SEO & digital marketing goals.
                                                </CardFooter>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="14">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "14" ? animationClass : previousTab === "13" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Social Media Management & <br />Creation
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg14} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>Your Brand's Trusted Partner</CardTitle>
                                            <CardText className='text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Target the Right Audience: </span>
                                                            Reach the perfect demographic with laser-focused content.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Craft Engaging Content: </span>
                                                            Secure data guides content creation for maximum engagement.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Optimize & Measure: </span>
                                                            Track results and refine strategies for peak performance.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <CardFooter className='bg-white px-0 text-black'>
                                                    We secure your data to fuel social media success.
                                                </CardFooter>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="15">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "15" ? animationClass : previousTab === "14" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Brand Evolution
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg15} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>The Strategic Foundation for Brand Evolution</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Granular Customer Insights: </span>
                                                            We analyze secure data to uncover hidden patterns, revealing a deeper understanding of your target audience..
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Brand Strategy Development: </span>
                                                            These insights inform a data-backed brand strategy, ensuring your message resonates and drives engagement.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Quantifiable Success Measurement: </span>
                                                            Secure data lets us track key metrics and optimize your brand strategy for continuous improvement and success.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <CardFooter className='bg-white px-0 text-black'>
                                                    We partner with you to evolve your brand with confidence, guided by the power of secure data.
                                                </CardFooter>
                                                <div className="py-3 d-flex justify-content-center " >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                            <TabPane tabId="16">
                                <div className='container'>
                                    <Card className={`card-bordered rounded-5 shadow-lg ff-alt ${activeTab === "16" ? animationClass : previousTab === "15" ? "card-disappear" : ""}`} style={{ height }}>
                                        <CardHeader className=" bg-white rounded-5 fs-1 text-black fw-bold ff-alt position-relative">Content Marketing
                                            <div className='text-center p-2 p-md-2 d-none d-lg-inline-block'>
                                                <img src={tabimg16} alt="service" className=' img-fluid cardimg rounded-5 position-absolute z-3' width={`40%`} />
                                            </div>
                                        </CardHeader>
                                        <CardBody className="card-inner">
                                            <CardTitle tag="h5" className='text-dark'>High-Impact Content Marketing</CardTitle>
                                            <CardText className='p-2 text-dark'>
                                                Our approach offers distinct advantages:
                                                <ul className='list'>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Targeted Content: </span>
                                                            Craft hyper-personalized content that resonates with specific audiences, driving deeper engagement.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Data-Driven Strategy: </span>
                                                            Analyze user behaviour to create content aligned with user intent, maximizing reach and qualified traffic.
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <p>
                                                            <span className='fw-semibold text-dark '>Measurable ROI: </span>
                                                            Track key metrics with secure data to optimize your strategy and ensure maximum return on investment.
                                                        </p>
                                                    </li>
                                                </ul>
                                                <CardFooter className='bg-white px-0 text-black'>
                                                    We secure your data to unlock content marketing's full potential.
                                                </CardFooter>
                                                <div className="py-3 d-flex justify-content-center" >
                                                    <Link to="contact"
                                                        className='scrollto'
                                                        smooth={true}
                                                        offset={-10}
                                                        duration={100}>
                                                        <Button className='ff-alt px-5 py-2 fs-6 ff button'>
                                                            <span className=''>Book Consultation</span>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </div>
                            </TabPane>
                        </TabContent>}
                    </Col>
                </Row >
            </div >
        </div >
    )
}

export default VerticalTabs
