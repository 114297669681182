import React from 'react'
import ConsultationForm from '../../components/common/ConsultationForm'
import imgUrl from '../../../images/axiom_img/background5.png';
import { toast } from 'react-toastify';

const SiloConsultation = () => {
    return (
        <div className='nk-content px-0 mt-5 pb-0'>
            <ConsultationForm
                apiUrl={`https://api.silocloud.io/api/v1/public/add-silosecure`}
                backgroundImage={imgUrl}
                title="Check out availability and book the date and time"
                onSuccess={(result) => toast.log('Form submitted successfully:', result)}
                onError={(error) => toast.error('Error submitting form:', error)}
                maxDays={30}
                minTime={new Date(0, 0, 0, 9, 0)}
                maxTime={new Date(0, 0, 0, 17, 0)} />
        </div>
    )
}

export default SiloConsultation
