import React, { useEffect, useState } from "react";
import Content from "../layout/content/Content";
// import Head from "../layout/head/Head";
import Home from "./axiomlayout/components/Home";
import Service from "./axiomlayout/components/Service";
import About from "./axiomlayout/components/About";
import Contact from "./axiomlayout/components/Contact";
import Testimonial from "./axiomlayout/pages/components/Testimonial";
// import { Helmet } from "react-helmet";
import { Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { Icon } from "../components/Component";
import WhitelabeledButton from "./axiomlayout/components/WhitelabeledButton";
// import WelcomeModal from "./axiomlayout/components/WelcomeModel";

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handlePageLoad = () => {
      sessionStorage.removeItem('modalShown');
    };

    window.addEventListener('beforeunload', handlePageLoad);

    const hasModalBeenShown = sessionStorage.getItem('modalShown');

    if (!hasModalBeenShown) {
      const timer = setTimeout(() => {
        setIsOpen(true);
        sessionStorage.setItem('modalShown', 'true');
      }, 2500);

      return () => {
        clearTimeout(timer);
        window.removeEventListener('beforeunload', handlePageLoad);
      };
    }
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Head title="Axiom" /> */}
      <Content>
        <Home />
        <Service />
        <About />
        <Contact />
        <Testimonial />
      </Content>
      <Modal size="lg" isOpen={isOpen} onRequestClose={closeModal}>
        <ModalBody>
          <button className="close" onClick={closeModal}>
            <Icon name="cross" />
          </button>
          <Card>
            <CardHeader className="px-0 bg-transparent ff fs-5 fw-bold text-primary">
              Unlock Your Business Potential with a Free Consultation!
              <p className="sub-text">Get expert advice on how our software solutions can drive your success.</p>
            </CardHeader>
            <CardBody>
              <Row className="pb-5">
                <Col>
                  <h6 className="ff">What You Get:</h6>
                  <p className="list">
                    <li>
                      Comprehensive analysis of your business needs
                    </li>
                    <li>
                      Personalized software recommendations
                    </li>
                    <li>
                      Roadmap to implement effective solutions
                    </li>
                  </p>
                </Col>
                <Col>
                  <h6 className="ff">Why Choose Us:</h6>
                  <p className="list">
                    <li>
                      Identify the best software solutions for your business
                    </li>
                    <li>
                      Receive expert guidance from experienced professionals
                    </li>
                    <li>
                      Get a tailored strategy to boost productivity and efficiency
                    </li>
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <WhitelabeledButton
            to="/consultation"
            className={"text-center"}
            buttonText="Schedule Now"
            buttonOutline
            buttonColor="primary" />
        </ModalBody>
        <ModalFooter className="bg-light text-center justify-content-center">
          <h3 className="ff text-primary">
            Schedule Your Free Consultation Today!
          </h3>
          <div>
            <span>
              Call us at:
              <a href="tel:469.324.9860">   469.324.9860</a>
            </span>
          </div>
          <div>
            <span>
              Email us at:
              <a href="mailto:info@axiomwebcontrol.com">  info@axiomwebcontrol.com</a>
            </span>
          </div>
        </ModalFooter>
      </Modal>
      {/* <WelcomeModal
        isOpen={isOpen}
        onClose={closeModal}
        title="Unlock Your Business Potential with a Free Consultation!"
        subtitle="Get expert advice on how our software solutions can drive your success."
        leftColumnTitle="What You Get:"
        leftColumnItems={[
          "Comprehensive analysis of your business needs",
          "Personalized software recommendations",
          "Roadmap to implement effective solutions"
        ]}
        rightColumnTitle="Why Choose Us:"
        rightColumnItems={[
          "Identify the best software solutions for your business",
          "Receive expert guidance from experienced professionals",
          "Get a tailored strategy to boost productivity and efficiency"
        ]}
        ctaButtonText="Schedule Now"
        // ctaButtonLink="/consultation"
        footerTitle="Schedule Your Free Consultation Today!"
        phoneNumber="469.324.9860"
        email="info@axiomwebcontrol.com"
      /> */}
    </React.Fragment>
  );
};

export default HomePage;
