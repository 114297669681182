import React, { useState } from 'react';
import Content from '../../../../layout/content/Content';
import imgUrl from '../../../../images/axiom_img/background5.png';
import { Button, Col, Row } from 'reactstrap';
import { Icon, PreviewCard } from '../../../../components/Component';
import DatePicker from "react-datepicker";
// import ConsultationForm from '../../../components/common/ConsultationForm';

const Consultation = () => {
    const [formData, setFormData] = useState({
        date: null,
        time: null,
        full_name: '',
        email: '',
        phone: '',
        message: '',

    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitMessage, setSubmitMessage] = useState({ type: '', text: '' });
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + 30);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (date) => {
        setFormData(prev => ({ ...prev, date }));
    };

    const handleTimeChange = (time) => {
        setFormData(prev => ({ ...prev, time }));
    };

    const validateForm = () => {
        let newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\d{10}$/;

        if (!formData.full_name.trim()) newErrors.full_name = 'Full Name is required';
        if (!formData.email.trim() || !emailRegex.test(formData.email)) newErrors.email = 'Valid email is required';
        if (!formData.phone.trim() || !phoneRegex.test(formData.phone)) newErrors.phone = 'Valid 10-digit phone number is required';
        if (formData.message.trim().length > 500) newErrors.message = 'Message must be 500 characters or less';
        if (!formData.date) newErrors.date = 'Date is required';
        else if (formData.date < today || formData.date > maxDate) newErrors.date = 'Please select a date within the next 30 days';
        if (!formData.time) newErrors.time = 'Time is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitMessage({ type: '', text: '' });

        if (validateForm()) {
            setIsSubmitting(true);
            try {
                const formattedDate = formData.date ? formData.date.toISOString().split('T')[0] : null;
                const formattedTime = formData.time ? formData.time.toTimeString().slice(0, 5) : null;

                const dataToSend = {
                    ...formData,
                    date: formattedDate,
                    time: formattedTime,
                };

                const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/consultation`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(dataToSend),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Server responded with an error');
                }

                const result = await response.json();
                // console.log('Form submitted successfully:', result);
                // console.log('Sending data:', dataToSend);
                setSubmitMessage({ type: 'success', text: 'Consultation scheduled successfully!' });
                setFormData({
                    date: null,
                    time: null,
                    full_name: '',
                    email: '',
                    phone: '',
                    message: '',
                });
            } catch (error) {
                console.error('Error submitting form:', error);
                setSubmitMessage({ type: 'error', text: error.message || 'An error occurred. Please try again.' });
            } finally {
                setIsSubmitting(false);
            }
        } else {
            setSubmitMessage({ type: 'error', text: 'Please correct the errors in the form.' });
        }
    };

    return (
        <Content>
            <div className="container-fluid p-3 m-auto" id='contactus' style={{
                backgroundImage: `url(${imgUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}>
                <div className="container py-md-4 py-2">
                    <div className='pt-3'>
                        <h4 className='ff text-primary'>Check out availability and book the date and time</h4>
                    </div>
                    <PreviewCard className='bg-transparent'>
                        <form onSubmit={handleSubmit} className="gy-3">
                            <Row>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Date</label>
                                        <DatePicker
                                            selected={formData.date}
                                            onChange={handleDateChange}
                                            className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                                            placeholderText="Select a date YYYY/MM/DD"
                                            dateFormat="yyyy/MM/dd"
                                            minDate={today}
                                            maxDate={maxDate}
                                            filterDate={date => date.getDay() !== 0 && date.getDay() !== 7}
                                        />
                                        {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="form-group">
                                        <label className="form-label">Select Time</label>
                                        <DatePicker
                                            selected={formData.time}
                                            onChange={handleTimeChange}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={60}
                                            timeCaption="Time"
                                            dateFormat="HH:mm"
                                            timeFormat="HH:mm"
                                            placeholderText="Select a time"
                                            className={`form-control ${errors.time ? 'is-invalid' : ''}`}
                                            minTime={new Date(0, 0, 0, 9, 0)}
                                            maxTime={new Date(0, 0, 0, 17, 0)}
                                        />
                                        {errors.time && <div className="invalid-feedback">{errors.time}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <div className='border p-2 rounded-2'>
                                <Row className='py-3'>
                                    <Col lg="6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="full_name">Full Name</label>
                                            <input
                                                type="text"
                                                id="full_name"
                                                name="full_name"
                                                value={formData.full_name}
                                                onChange={handleInputChange}
                                                className={`form-control ${errors.fullName ? 'is-invalid' : ''}`}
                                            />
                                            {errors.fullName && <div className="invalid-feedback">{errors.fullName}</div>}
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                            />
                                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='py-3'>
                                    <Col lg="6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="phone">Phone</label>
                                            <input
                                                type="tel"
                                                id="phone"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                                className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                            />
                                            {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="message">Message</label>
                                            <textarea
                                                id="message"
                                                name="message"
                                                value={formData.message}
                                                onChange={handleInputChange}
                                                className={`form-control ${errors.message ? 'is-invalid' : ''}`}
                                            />
                                            {errors.message && <div className="invalid-feedback">{errors.message}</div>}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <Row className="g-3">
                                <Col lg="7" className="offset-lg-5">
                                    <div className="form-group mt-2">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="lg"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? 'Scheduling...' : 'Schedule Now'}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            {submitMessage.text && (
                                <div className={`alert alert-${submitMessage.type}`}>
                                    {submitMessage.text}
                                </div>
                            )}
                        </form>
                    </PreviewCard>
                </div>
            </div>
            {/* <ConsultationForm
                apiUrl={`${process.env.REACT_APP_API_URL}api/v1/public/consultation`}
                backgroundImage={imgUrl}
                title="Check out availability and book the date and time"
                onSuccess={(result) => console.log('Form submitted successfully:', result)}
                onError={(error) => console.error('Error submitting form:', error)}
                maxDays={30}
                minTime={new Date(0, 0, 0, 9, 0)}
                maxTime={new Date(0, 0, 0, 17, 0)} /> */}
        </Content>
    );
};

export default Consultation;